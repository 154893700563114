import React  from 'react';
import '../styles.scss'
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import { useTranslation } from '../../../utils/contexts/Localization'

Banner.propTypes = {
    data : PropTypes.array,
};

function Banner (props) {
    const {data} = props;
    const { t } = useTranslation();

    return (

        <div className="page-title">
            <div className="icon_bg">
                <img src={require ('../../../assets/images/backgroup/bg_inner_slider.png')} alt="Risebot" />
            </div>
            <div className="slider-main">
            <Swiper
                modules={[Navigation]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation 
                >
                {
                    data.slice(0,2).map(item => (
                        <SwiperSlide key={item.id}>
                            <div className="slider-st2">
                                <img className="icon icon_1" width="140px" src={require ("../../../assets/images/common/icon1_slider_2.png")} alt="" />
                                <img  width="400px" className="icon icon_2" src={require ("../../../assets/images/common/10000.png")} alt="" />
                                <img className="icon icon_3" src={require ("../../../assets/images/common/icon3_slider_2.png")} alt="" />
                                <div className="overlay">
                                    <img src={require ("../../../assets/images//backgroup/bg-slider2.png")} alt="" />
                                </div>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="box-slider">
                                                <div className="content-box">
                                                    <h1 className="title" >{t('The New Vision Fund aims to invest in fields such as the future, metaverse, AI, and digital currencies.')}</h1>
                                                    <p className="sub-title">{t('NVF is the leading blockchain gaming launchpad with over 50 projects launched and the')} <br className="show-destop" /> {t("highest average ROI in the industry")}</p>
                                                    <div className="wrap-btn">
                                                        <Link to="/faqs" className="tf-button style2">
                                                            {t("HOW TO START")}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
            </div>
        </div>
    );
}

export default Banner;