import { ethers } from 'ethers';

// if (location.search && location.search.match(/enter-test-mode/)) localStorage.setItem('IsWolfTownTest', 'true');
// localStorage.setItem('IsWolfTownTest', 'true');
// const IsTest = localStorage.getItem('IsWolfTownTest') === 'true';

let DEBUG_ADDRESS = '';


const IsTest = false;

export const ImgUrl = "https://img.pandagame.space/img/";

export const API_NVF = "https://apis.33125.pw/";


const IsDevelopment = process.env.NODE_ENV === 'development';
export const Constants = {
  ArenaHistoryBlock: (24 * 60 * 60 * 30) / 3,
  DEBUG_ADDRESS,
  IsDevelopment,
  DEFAULT_SEED: '0x0000000000000000000000000000000000000000000000000000000000000000',
  /**
   * 每个邀请最多可持续的时间 (hours)
   */
  INVITATION_DURATION: 72,

  IsTest,
  BASE_URL: 'https://app.wolftown.world/animals',
  BASE_IMAGE_URL: 'https://app.wolftown.world/images/animals',
  API_SERVE: 'https://app.wolftown.world',
  OPENSEA_URL: 'https://opensea.io',
  Chain: {
    ID: '1',
    PRC: 'https://web3.mytokenpocket.vip',
    Name: 'EtH Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    ScanView: 'https://ethereum.org/',
    AddressView: 'https://ethereum.org/address',
    TxView: 'https://ethereum.org/tx',
  },

  emergencyWolfLeave: false,

  Building: {
    OwnershipTotal: 10000000,
  },
  Contract: {
    BankAddress: '0x1f0c693F5cD661126e43b25a96FA703a0C8F2543',
    PandaToken: '0xb87C087D0f6FBe2F7223C5410291Cb82744102d4',
    USDT: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    PiPandaMint: '0xe57F05aB9dc009c08bb082C0e24c420cbb22AC82',
    PiPandaMintUSDT: '0x9cbb01b1E9f894CF96C8997E1b04E61CC2e19664', 
    PiPandaNFT: '0xe3C0d538B9A8ad9c7f8aB70e1E5cbc6D6C5Ba863',
    BatchOpen:"0x0e7362026cB0C8E6A0F765d311b46d95aF3905c4",
    Persale:"0xA1324d511E6eBCD1c91B8B7c2424Cf5a4ee1D881" ,//admin  0x0e7fedc907d34352a4c3ec5372ae2357d8a4d130
    chainnftsign:"0x632da622c7cb610ffd2a553fa72a286813c1cf19" , //admin 0xC1C7216f0A32EC87a3a26B9390Ed7223a7cdA22e
   },

  //batch admin 0xc501fe2954f6BAC66E74e93EA0C841F1b5958B93
};
export const AbiConfig: Record<keyof typeof Constants.Contract, any> = {
  BankAddress: require('src/utils/abi/wtWool'),
  PandaToken: require('src/utils/abi/wtWool'),
  USDT: require('src/utils/abi/wtWool'), 
  PiPandaMint: require('src/utils/abi/PiPandaMint.json'),
  PiPandaMintUSDT: require('src/utils/abi/PiPandaMint.json'),
  PiPandaNFT: require('src/utils/abi/PiPandaNFT.json'), 
  BatchOpen: require('src/utils/abi/Batch.json'),
  Persale: require('src/utils/abi/Persale.json'), 
  chainnftsign: require('src/utils/abi/chainnftsign.json'), 
};
if (!IsDevelopment) {
  
}
if (IsTest) {
  Object.assign(Constants, {
    BASE_URL: 'https://app.test.wolftown.world/animals',
    BASE_IMAGE_URL: 'https://app.test.wolftown.world/images/animals',
    API_SERVE: 'https://app.test.wolftown.world',
    Chain: {
      ID: '97',
      PRC: 'https://rpc.ankr.com/bsc_testnet_chapel/',
      Name: 'Binance Smart Chain Testnet',
      nativeCurrency: {
        name: 'tBNB',
        symbol: 'tBNB',
        decimals: 18,
      },
      ScanView: 'https://testnet.bscscan.com',
      AddressView: 'https://testnet.bscscan.com/address',
      TxView: 'https://testnet.bscscan.com/tx',
    },
    Contract: {
      // api
      WoolfTownAPI: '0xbFE5a21f0c153144BE0c65BE961b01Bf52EE1B0f', 
      // contract
      PandaToken: '0xb0CBA35a4B39F6c8849dDa1cA9914fB0596bD6Cb',
      USDT: '0xaB1a4d4f1D656d2450692D237fdD6C7f9146e814',
      PiPandaMint: '0x28E31353d87C994748c61B9E6692B4E7bFb92951',
      PiPandaNFT: '0x05b0165931Ec5Cd245C1440134228edFf5fBEcB5',
      BatchOpen:"0x77395936b39263bc8540efc2f1a6d1822383ee42",   // 0xc40bcc616eE6F6e56FF66Bd128869f985CE07A10
      Persale:"0xcC9800c99bA412a5b3C15C2Afeae3d44b09b090b" ,  //admin  
      chainnftsign:"0x632da622c7cb610ffd2a553fa72a286813c1cf19" , //admin 0xC1C7216f0A32EC87a3a26B9390Ed7223a7cdA22e

    },
  });
}
