import React, { useState, useEffect } from 'react'
import PageTitle from '../components/pagetitle';
import CTA from '../components/cta';
import { Chart } from '../components/token/Chart';
import { getContractHandler, _getChain, _getAddress, useWalletHook, connectMetamask, watchTransaction, _getBalance } from '../utils/ethereum'
import { Constants } from '../utils/Config'
import useToast from '../utils/useToast'
import { BigNumber, Contract, providers, utils } from 'ethers'
import { useTranslation } from '../utils/contexts/Localization'
import { BackgroundImage } from '@pancakeswap/uikit';
import { Link } from 'react-router-dom';

import data from '../assets/fake-data/data-project2'

function Token(props) {

    const { toastSuccess, toastError } = useToast()
    const { t } = useTranslation();


    const [shouldGet, setShouldGet] = useState(0)

    const [shouldObtain, setShouldObtain] = useState(0)


    const [shouldMint, setShouldMint] = useState(0)

    const [loadding, setLoadding] = useState(false)

    const [buyNumber, setBuyBumber] = useState(0.1)

    const [myPersale, setMypersale] = useState(0)
    const [ETH, setETH] = useState(0)

    const [total, setTotal] = useState(0)

    const [totalTime, setTotalTime] = useState(0)


    const buyUseUSDT = async () => {

        try {
            setLoadding(true)

            await _getChain()
            const account = await _getAddress()
            await connectMetamask()
            const persale = await getContractHandler('Persale')


            /* const balance =    await  _getBalance()
  
            console.log("balance:",balance) */

            const hash = await persale.useETH("0x0000000000000000000000000000000000000000", { from: account, value: (utils.parseEther(buyNumber.toString())) + "" })

            watchTransaction(hash.hash, (res) => {
                console.log(res)
                setLoadding(false)
                getData()

                toastSuccess(t('Successfully submit!'))
            })


        } catch (e) {
            console.error(e)
            setLoadding(false)
            toastError(e?.reason || "余额不足")
        }

    }





    const getETHPrice = async () => {
        console.log("get price")
        const persale = await getContractHandler('Persale')
        if (persale) {
            const ethPrice = await persale.getETHPrice()

            setETH(Number(utils.formatUnits(ethPrice, 6)).toFixed(2));
        }

    }



    const getData = async () => {

        try {


            await _getChain()
            const account = await _getAddress()
            await connectMetamask()

            const persale = await getContractHandler('Persale')



            console.log("persale", persale)
            if (!persale) return

            const res1 = await persale.userBuyNumber(account)




            const res2 = await persale.totalSell()

            const res3 = await persale.currentTokenDepositId()


            /*  const res3=await persale.canMint(account)
             const res4=await persale.buyNumber(account) */

            console.log(res1, res2)

            /*  setShouldGet((utils.formatUnits(res1,0)-utils.formatUnits(res2,0))*1000);
             setShouldMint(utils.formatUnits(res3,0));
             setShouldObtain(utils.formatUnits(res4,0)*10000); */

            setMypersale(utils.formatUnits(res1, 6))
            setTotal(utils.formatUnits(res2, 6))
            setTotalTime(utils.formatUnits(res3, 0))



        } catch (e) {

            toastError(e?.message)
            console.error(e?.message)
        }

    }


    useEffect(() => {
        getData()
    }, [])


    useEffect(() => {
        getETHPrice();
        const intervalId = setInterval(() => {
            getETHPrice();
        }, 5000); // 每5秒执行一次

        return () => clearInterval(intervalId); // 组件卸载时清除定时器
    }, []);


    const handleInputChange = (e) => {
        // 直接更新状态为字符串值，不进行转换  
        setBuyBumber(e.target.value);
    };

    const handleInputBlur = (e) => {
        const value = e.target.value;
        // 使用正则表达式验证是否为有效数字  
        const isNumber = !isNaN(value) && value !== '' && value !== '-';
        if (isNumber) {
            // 如果是有效数字，则转换为数字类型（如果是小数则保留小数）  
            setBuyBumber(Number(value));
        } else {
            // 如果不是有效数字，则重置为null或其他你想要的默认值  
            setBuyBumber(null);
        }
    };


    return (
        <div className='inner-page'>
            {<PageTitle title={t("Token")} />}

            <section className="tf-section tf-token pt60">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="tf-title left mb40" data-aos-delay="500" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                    {t("Statistics token")}
                                </h2>
                            </div>
                            <div className="desc-token" data-aos-delay="500" data-aos="fade-up" data-aos-duration="800">
                                <div className="total-token">
                                    <p>{t("Total Supply")}: <span>10,000,000,000</span></p>
                                    <p>{t("Public sale")}: <span> $0.1</span></p>
                                </div>
                                <ul className="describe_chart">
                                    <li>
                                        <img src={require("../assets/images/chart/color_1.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("liquidity Pool")}</p>
                                            <p className="number">15%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={require("../assets/images/chart/color_2.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("Persale")}</p>
                                            <p className="number">25%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={require("../assets/images/chart/color_3.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("Ecosystem")}</p>
                                            <p className="number">7.5%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={require("../assets/images/chart/color_4.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("Advisor")}</p>
                                            <p className="number">7.5%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={require("../assets/images/chart/color_5.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("Private Sale")}</p>
                                            <p className="number">10%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={require("../assets/images/chart/color_6.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("Exchange")}</p>
                                            <p className="number">25%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={require("../assets/images/chart/color_7.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("Marketing")}</p>
                                            <p className="number">5%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src={require("../assets/images/chart/color_8.png")} alt="" />
                                        <div className="desc">
                                            <p className="name">{t("Team")}</p>
                                            <p className="number">5%</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="tf-title left mt17 mb40" data-aos="fade-up" data-aos-duration="800">
                                <h2 className="title">
                                    {t("Persale")}
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="tokenomics-wrapper" data-aos="fade-up" data-aos-duration="800">
                                <div className="box">
                                    <div className="td td1">
                                        <p>{t("Category")}</p>
                                        <p>{t("Persale")}</p>
                                    </div>
                                    <div className="td td2">
                                        <p>{t("Allocation")} ETH</p>
                                        <p>
                                            <span style={{ fontSize: "30px", cursor: "pointer" }} onClick={() => { if (Number(buyNumber) > 0.1) { setBuyBumber((Number(buyNumber) - 0.1).toFixed(5)) } }} >- &nbsp;</span>
                                            <input type="number" className='input-sm' value={buyNumber} onChange={handleInputChange}
                                                onBlur={handleInputBlur} />
                                            <span onClick={() => { setBuyBumber((Number(buyNumber) + 0.1).toFixed(5)) }} style={{ fontSize: "25px", cursor: "pointer" }}>&nbsp;+</span></p>
                                    </div>
                                    <div className="td td3">
                                        <p>{t("Number")} </p>
                                        <p>{Number(buyNumber * ETH * 10).toFixed(5)} NVF</p>
                                    </div>
                                    <div className="td td4">
                                        <p>{t("Price")}</p>
                                        <p>$0.1 USDT/NVF</p>
                                    </div>
                                    <div className="td td5">
                                        <p>{t("option")}</p>
                                        <p>
                                            <button type="submit" style={{ background: loadding ? "#798DA3" : "#a526f0" }} className="tf-button style2" onClick={buyUseUSDT}>
                                                {t("Buy")}
                                            </button>
                                        </p>
                                    </div>
                                </div>

                                <div className="box">
                                    <div className="td td1">
                                        <p>{t("My Persale")}</p>
                                    </div>
                                    <div className="td td4">
                                        <p>{t("total Pay")}</p>
                                        <p>{Number(myPersale).toFixed(3)} ≈USDT</p>
                                    </div>
                                    <div className="td td4">
                                        <p>{t("total Get")}</p>
                                        <p>{Number(myPersale).toFixed(3) * 10} NVF</p>
                                    </div>
                                </div>

                                <div className="box">
                                    <div className="td td1">
                                        <p>{t("Total pre-sales")}</p>
                                    </div>
                                    <div className="td td4">
                                        <p>{t("total payment")}</p>
                                        <p>{Number(total).toFixed(3)} USDT</p>
                                    </div>
                                    <div className="td td5">
                                        <p>{t("total distribution")}</p>
                                        <p>{Number(total).toFixed(3) * 10} NVF</p>
                                    </div>
                                    <div className="td td5">
                                        <p>{t("Total times")}</p>
                                        <p>{totalTime} {t("Times")}</p>
                                    </div>
                                </div>


                                <section className="tf-section project_1">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="tf-title left mb40" data-aos="fade-up" data-aos-duration="800">
                                                    <h2 className="title">

                                                        {t("Collaborative platform")}
                                                    </h2>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="project_wrapper_1">
                                                    {
                                                        data.slice(0, 2).map(item => (
                                                            <div key={item.id} className="project-box-style4">
                                                                <div className="image">
                                                                    <div className="img_inner">
                                                                        <img src={item.img} alt="" />
                                                                        <img className='shape' src={require('../assets/images/common/shape.png')} alt="Risebot" />
                                                                    </div>
                                                                    <div className="label">
                                                                        {t("1ST Phase")}
                                                                    </div>
                                                                </div>
                                                                <div className="content">
                                                                    <h5 className="heading"><Link target='_blank' to={item.link}>{item.title}</Link></h5>
                                                                    <p className="desc">{t(item.desc)}</p>
                                                                    <ul>
                                                                        <li>
                                                                            <p className="text">Min allocation</p>
                                                                            <p className="price">{item.price_1}</p>
                                                                        </li>
                                                                        <li>
                                                                            <p className="text">Maximum</p>
                                                                            <p className="price">{item.price_2}</p>
                                                                        </li>
                                                                        {/*  <li>
                                                        <p className="text">Access</p>
                                                        <p className="price">{item.price_3}</p>
                                                    </li> */}
                                                                    </ul>

                                                                </div>
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {<CTA />}

        </div>

    );
}

export default Token;