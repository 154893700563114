import { EN,ZH } from '../../../config/localization/languages'
import   zhh   from '../../../locales/zh/translation.json'
import   een    from '../../../locales/zh/translation.json'

 


const publicUrl = process.env.PUBLIC_URL

export const LS_KEY = 'pancakeswap_languageS'

export const fetchLocale = async (locale) => {
 

  console.log("testtt",locale)
 
  return locale=="zh"?zhh:een
}

export const getLanguageCodeFromLS = () => {
  try {
    const codeFromStorage = localStorage.getItem(LS_KEY)

    return codeFromStorage || ZH.locale
  } catch {
    return ZH.locale
  }
}
