import React, { useState, useEffect } from 'react'

import { Link, NavLink } from 'react-router-dom'
import menus from '../../pages/menu'
import Button from '../button/index'
import './styles.scss'
import logo from '../../assets/images/logo/logo2.png'
import { _getChain, useWalletHook ,connectMetamask,switchToMainnet,IsBscNetAndWalletHas} from '../../utils/ethereum.tsx'
import ConnectWallet from "../../pages/ConnectWallet";
import { useTranslation } from '../../utils/contexts/Localization'

import LangSelector from "../../utils/LangSelector";
import {languageList} from "../../config/localization/languages";

const Header = () => {
  const [scroll, setScroll] = useState(false)
  const [wattleChoise, setWattleChoise] = useState(false)
  const { t } = useTranslation();

  const env = useWalletHook()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 300)
    })
    return () => {
      setScroll({})
    }

    //地址处理
  }, [])

  useEffect(() => {

    console.log("IsBscNetAndWalletHas()",IsBscNetAndWalletHas())

    const  ttt= !IsBscNetAndWalletHas()

    if(ttt){
      switchToMainnet()
    } 
   
  }, [env.chain])

  const [menuActive, setMenuActive] = useState(null)

  const handleMenuActive = () => {
    setMenuActive(!menuActive)
  }

 
  const [activeIndex, setActiveIndex] = useState(null)
  const handleDropdown = (index) => {
    setActiveIndex(index)
  }

  return (
    <>  
    {/*  {wattleChoise&&<ConnectWallet close ={()=>{
          
     }}/>}  */}
    <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            
            <NavLink to="/HomeTwo">
              <img style={{height:"50px"}} src={logo} alt="Risebot" />
            </NavLink>
          </div>
          <nav
            id="main-nav"
            className={`main-nav ${menuActive ? 'active' : ''}`}
          >
            <ul id="menu-primary-menu" className="menu">
              {menus.map((data, idx) => (
                <li
                  key={idx}
                  onClick={() => handleDropdown(idx)}
                  className={`menu-item ${
                    data.namesub ? 'menu-item-has-children' : ''
                  } ${activeIndex === idx ? 'active' : ''}`}
                >
                  <Link to={data.links}>{t(data.name)}</Link>
                  {data.namesub && (
                    <ul className="sub-menu">
                      {data.namesub.map((submenu) => (
                        <li key={submenu.id} className="menu-item">
                          <NavLink to={submenu.links} onClick={handleDropdown}>
                            {t(submenu.sub) }
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <LangSelector
              currentLang="zh"
              langs={languageList} 
              dropdownPosition="top-right"
            />
          <Button
            title={
              env.wallet
                ? env.wallet.slice(0, 4) + '...' + env.wallet.slice(-4)
                : 'Connect'
            }
            path="/HomeTwo"
            addclass="style1"
           /*  onClick={()=>{if(!env.wallet){
                    setWattleChoise(true)
            }}} */

            onClick={()=>{if(!env.wallet){

              
              connectMetamask()
              

             
            }}}
          />

       
          <div
            className={`mobile-button ${menuActive ? 'active' : ''}`}
            onClick={handleMenuActive}
          >
            <span></span>
         
          </div>
        </div>
      </div>
    </header>
    </>
  )
}

export default Header
