

const dataToken = [
    {
        id: 1,
        title: 'Farming Pool',
    }, 
    {
        id: 2,
        title: 'Staking',
    }, 
    {
        id: 3,
        title: 'Ecosystem',
    }, 
    {
        id: 4,
        title: 'Advisor',
    }, 
    {
        id: 5,
        title: 'Private Sale',
    }, 
    {
        id: 6,
        title: 'Liquidity',
    }, 
    {
        id: 7,
        title: 'Marketing',
    }, 
    {
        id: 8,
        title: 'Team',
    }, 

]

export default dataToken;